import { Config } from './index';
import devConfig from './dev.config';
import { cloneDeep } from 'lodash';

// Clone the dev config to avoid modifying it directly
const config: Config = cloneDeep(devConfig);

// Override specific values for local environment
config.graph = 'http://localhost:4000';

// Add disabled flag to sentry config
config.sentry.disabled = true;

// Override all domain base URLs to localhost
Object.keys(config.domains).forEach((locale) => {
  const domain = config.domains[locale as keyof typeof config.domains];
  domain.base = 'http://localhost:3000';
});

export default config;
