import { Config } from './index';

const config: Config = {
  graph: 'https://graph.crazygames.com',
  ga4ID: 'G-7YCCNTJ3D9',
  firebase: {
    apiKey: 'AIzaSyAcnWHv0-RhlmU5ONOXzOSP0Q6ORQEMrNg',
    authDomain: 'crazygames-prod.firebaseapp.com',
    databaseURL: 'https://crazygames-prod.firebaseio.com',
    projectId: 'crazygames-prod',
    storageBucket: 'crazygames-prod.appspot.com',
    messagingSenderId: '487034968410',
  },
  sentry: {
    dsn: 'https://2beef08402894169a6dcee68036eccf4@sentry.io/1284892',
    disabled: true,
  },
  aws: {
    filesDomain: 'files.crazygames.com',
    videoCdnPrefix: 'https://videos.crazygames.com',
    imagesCdnPrefix: 'https://imgs.crazygames.com',
  },
  domains: {
    en_US: {
      base: 'https://www.crazygames.com',
      pathname: '',
    },
    nl_NL: {
      base: 'https://www.crazygames.nl',
      pathname: '',
    },
    it_IT: {
      base: 'https://it.crazygames.com',
      pathname: '',
    },
    es_ES: {
      base: 'https://www.crazygames.com',
      pathname: '/es',
    },
    id_ID: {
      base: 'https://www.crazygames.co.id',
      pathname: '',
    },
    fr_FR: {
      base: 'https://www.crazygames.fr',
      pathname: '',
    },
    pt_BR: {
      base: 'https://www.crazygames.com.br',
      pathname: '',
    },
    ru_RU: {
      base: 'https://www.crazygames.ru',
      pathname: '',
    },
    de_DE: {
      base: 'https://de.crazygames.com',
      pathname: '',
    },
    pl_PL: {
      base: 'https://www.crazygames.pl',
      pathname: '',
    },
    ro_RO: {
      base: 'https://www.crazygames.ro',
      pathname: '',
    },
    nb_NO: {
      base: 'https://www.crazygames.no',
      pathname: '',
    },
    sv_SE: {
      base: 'https://www.crazygames.se',
      pathname: '',
    },
    fi_FI: {
      base: 'https://www.crazygames.fi',
      pathname: '',
    },
    uk_UA: {
      base: 'https://www.crazygames.com.ua',
      pathname: '',
    },
    el_GR: {
      base: 'https://gr.crazygames.com',
      pathname: '',
    },
    da_DK: {
      base: 'https://www.crazygames.com',
      pathname: '/dk',
    },
    cs_CZ: {
      base: 'https://www.crazygames.cz',
      pathname: '',
    },
    hu_HU: {
      base: 'https://www.crazygames.hu',
      pathname: '',
    },
    tr_TR: {
      base: 'https://www.crazygames.com',
      pathname: '/tr',
    },
    ar_SA: {
      base: 'https://ar.crazygames.com',
      pathname: '',
    },
    vi_VN: {
      base: 'https://vn.crazygames.com',
      pathname: '',
    },
    th_TH: {
      base: 'https://th.crazygames.com',
      pathname: '',
    },
    ko_KR: {
      base: 'https://www.crazygames.co.kr',
      pathname: '',
    },
  },
  kidSiteDomain: {
    base: 'https://kids.crazygames.com',
  },
};

export default config;
